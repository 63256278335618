import * as assign from 'lodash/assign';
import * as debounce from 'lodash/debounce';
import { Entities } from '@inwink/entities/entities';
import { logging } from '@inwink/logging';
import * as Data from '@@event/data/user';
import { eventUserBootstrapModule, eventUserModule } from '@@routes/appmodules';
import { eventCheckForUser } from '@@event/services/eventservice.checkuser';
import { appSplashActions } from '@@services/splashservice';
import { States } from '@@services/services';
import { appsplashuserservice } from '@@services/splashservice.codes';
import { userMessageActions } from '@@services/usermessageactions';
import { IProfileModalProps } from '@@event/components/appheader/appheader.profilemodal';
import { History } from 'history';

const logger = logging.getLogger("Data");
const _g = global as any;

function personAPI() {
    return import("@@event/api/person.me");
}

function getCurrentUserEventData(storedata: States.IAppState) {
    const selects: any = {
        $all: true,
        availabilities: {
            $all: true
        }
    };
    if (storedata.event && storedata.event.detail && storedata.event.detail.configuration) {
        const cfg = storedata.event.detail.configuration;
        if (cfg.exhibitors && cfg.exhibitors.hasExhibitors) {
            selects.exhibitorAccounts = {
                $all: true,
                exhibitor: {
                    $all: true,
                    badgeQuotaInfos: {
                        $all: true
                    }
                }
            };
        }
    }
    return personAPI().then((mod) => mod.getMe(storedata.event.requestManagers, selects, storedata.i18n.currentLanguageCode));
}

export const currentUserActions = {
    setCurrentUser(user: Entities.IAppUser, userdata: States.IPersonDataStore) {
        return (dispatch, getState: () => States.IAppState) => {
            return eventUserModule().then((mod) => {
                mod.initUserEvent();
                dispatch({
                    type: "USER_SETCURRENT",
                    payload: {
                        detail: user,
                        data: userdata
                    }
                });

                const state = getState() as States.IAppState;
                if (_g.localStorage) {
                    _g.localStorage[state.event.eventid + "-currentuser"] = user.id;
                }
            });
        };
    },
    showProfile: (i18nHelper: Entities.i18nHelper, history: History, target: HTMLElement) => {
        return (dispatch, getState) => {
            return import("@@event/components/appheader/appheader.profilemodal").then((mod) => {
                const state: States.IAppState = getState();
                const props: IProfileModalProps = {
                    i18nHelper,
                    user: state.user,
                    event: state.event,
                    history: history,
                };

                return userMessageActions.showMessage(
                    i18nHelper,
                    mod.ProfileModal,
                    props,
                    target,
                    null,
                    null,
                    "profile-modal"
                )(dispatch);
            });
        };
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    debouncedCheckCurrentUser(dispatch, getState: () => States.IAppState) {
    },

    checkCurrentUser(): (dispatch, getState: () => States.IAppState) => Promise<any> {
        return (dispatch, getState: () => States.IAppState) => {
            if (__SERVERSIDE__ || (global as any).disableAuthenticatedFeatures) {
                return Promise.resolve();
            }
            const storedata = getState();

            return storedata.event.requestManagers.initPromise.then(() => {
                return storedata.event.requestManagers.userActions.localUser().then((user) => {
                    if (user && (user.scopes && user.scopes.indexOf("anonymous-token") < 0)) {
                        if (storedata.event && storedata.event.eventid) {
                            if (_g.localStorage) {
                                const userid = _g.localStorage[storedata.event.eventid + "-currentuser"]
                                    || user.profile.sub;

                                if (userid) {
                                    let userdatastore;
                                    if (storedata?.user?.currentUser?.detail && storedata.user.currentUser.detail.id === userid) {
                                        userdatastore = storedata.user.currentUser.data;
                                    }
                                    if (!userdatastore) {
                                        userdatastore = Data.getUserRepository(storedata.event, userid);
                                    }

                                    return userdatastore.ready.then(() => {
                                        if (userdatastore?.userDetail?.data?.length) {
                                            const userdata = userdatastore.userDetail.data[0];
                                            logger.debug("loading local user data");
                                            return currentUserActions.setCurrentUser(userdata, userdatastore)(dispatch, getState)
                                                .then(() => {
                                                    return eventUserBootstrapModule().then((mod) => {
                                                        const uba = mod.userBootstrapActions;
                                                        return uba.bootstrapCurrentUser()(dispatch, getState);
                                                    });
                                                });
                                        }

                                        logger.debug("loading user data from remote (no local data)");
                                        const storageKey = storedata.event.eventid + "-currentuser-details";
                                        return getCurrentUserEventData(storedata).then((userdata: Entities.IAppUser) => {
                                            if (!!userdata !== true) {
                                                return storedata.event.requestManagers.userActions.refreshToken()
                                                    .then(() => {
                                                        return getCurrentUserEventData(storedata);
                                                    });
                                            }
                                            return Promise.resolve(userdata);
                                        }).then((userdata) => {
                                            if (userdata) {
                                                _g.localStorage[storageKey] = JSON.stringify(userdata);
                                                return currentUserActions.initCurrentUser(userdata)(dispatch, getState);
                                            }
                                            return Promise.reject(new Error("unable to reload user details me"));
                                        }).catch((error) => { // allow app to launch into offline mode ?
                                            const userdata = _g.localStorage[storageKey];
                                            if (userdata) {
                                                return currentUserActions
                                                    .initCurrentUser(JSON.parse(userdata))(dispatch, getState);
                                            }
                                            console.error(error);
                                        });
                                    });
                                }
                            }

                            logger.debug("loading user data from remote");
                            return getCurrentUserEventData(storedata).then((userdata: Entities.IAppUser) => {
                                return currentUserActions.initCurrentUser(userdata)(dispatch, getState);
                            });
                        }
                    } else if (storedata.event?.requestManagers?.userActions?.getToken) {
                        return storedata.event.requestManagers.userActions.getToken();
                    }
                });
            }).then(() => {
                dispatch({ type: "USER_CHECKED" });
                appSplashActions.unregisterService(appsplashuserservice)(dispatch, getState);
            });

            // dispatch({ type : "LOGGING",  payload : null});
        };
    },

    initCurrentUser(userdata: Entities.IAppUser) {
        return (dispatch, getState: () => States.IAppState) => {
            const storedata = getState();
            const previousUser = storedata?.user?.currentUser?.detail?.id;
            let userdatastore;
            if (previousUser && previousUser === userdata.id) {
                userdatastore = storedata.user.currentUser.data;
            }
            if (!userdatastore) {
                userdatastore = Data.getUserRepository(storedata.event, userdata.id);
            }

            return userdatastore.ready.then(() => {
                return currentUserActions.setCurrentUser(userdata, userdatastore)(dispatch, getState).then(() => {
                    return eventCheckForUser(dispatch, getState);
                });
            }).then(() => {
                return eventUserBootstrapModule().then((mod) => {
                    return mod.userBootstrapActions.bootstrapCurrentUser()(dispatch, getState);
                });
            });
        };
    },

    reloadCurrentUser() {
        return (dispatch, getState: () => States.IAppState) => {
            const state = getState();
            if (state.user.currentUser && state.user.currentUser.data) {
                const userdatastore = state.user.currentUser.data;
                if (userdatastore.userDetail && userdatastore.userDetail.data && userdatastore.userDetail.data.length) {
                    const userdata = userdatastore.userDetail.data[0];
                    logger.debug("loading local user data");
                    return currentUserActions.setCurrentUser(userdata, userdatastore)(dispatch, getState);
                }
            }
        };
    },

    updateCurrentUser(user: Entities.IAppUser) {
        return (dispatch, getState: () => States.IAppState) => {
            const state = getState();

            const existing = state.user.currentUser.data.userDetail.data.find((u) => u.id === user.id);
            if (existing) {
                assign(existing, user);
                state.user.currentUser.data.userDetail.update(existing);
            } else {
                state.user.currentUser.data.userDetail.insert(user);
            }

            return state.user.currentUser.data.save().then(() => {
                dispatch({
                    type: "USER_SETCURRENT",
                    payload: {
                        detail: existing || user,
                        data: state.user.currentUser.data
                    }
                });
            });
        };
    },

    dataChanged(userData?: States.IPersonDataStore) {
        return (dispatch) => {
            dispatch({ type: "USER_DATACHANGED", payload: { userData } });
        };
    },

    notifyUserReady() {
        return (dispatch, getState: () => States.IAppState) => {
            const state = getState();
            if (!state.user.isSyncReady) {
                dispatch({ type: "USER_SYNCREADY", payload: null });
            }
        };
    },
};

currentUserActions.debouncedCheckCurrentUser = debounce((dispatch, getState: () => States.IAppState) => {
    return currentUserActions.checkCurrentUser()(dispatch, getState).then(null, (err) => {
        logger.error("error checking current user", err);
    });
}, 400);
